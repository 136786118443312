
import { OrderedListOutlined, UploadOutlined } from '@ant-design/icons-vue';
import { message } from 'ant-design-vue';
import axios from 'axios';
import { Vue, Options } from 'vue-class-component';
import infoComponentVue from '../components/info.component.vue';
import { ref } from 'vue';

interface FileItem {
    uid: string;
    name?: string;
    status?: string;
    response?: string;
    url?: string;
    preview?: string;
    originFileObj?: any;
    file: string | Blob;
}

@Options({
    components: {
        'info': infoComponentVue,
        'extension-table-outlined': OrderedListOutlined,
        'upload-outlined': UploadOutlined
    }
})
export default class AbgCategoryMapping extends Vue {
    loading = true;
    fileList = ref<FileItem[]>([]);
    csvFile: any = null;
    csvFileLabel: any = '';
    loadingUpload = false;
    columns = [
        {
            title: 'Div',
            dataIndex: 'division',
        },
        {
            title: 'Div Dsc',
            dataIndex: 'division_description'
        },
        {
            title: 'Dpt',
            dataIndex: 'department',
        },
        {
            title: 'Dpt Dsc',
            dataIndex: 'department_description',
        },
        {
            title: 'Grp',
            dataIndex: 'group',
        },
        {
            title: 'Grp Dsc',
            dataIndex: 'group_description',
        },
        {
            title: 'Fam',
            dataIndex: 'family',
        },
        {
            title: 'Fam Dsc',
            dataIndex: 'family_description',
        },
        {
            title: 'Subfam',
            dataIndex: 'subfamily',
        },
        {
            title: 'Subfam Dsc',
            dataIndex: 'subfamily_description',
        },
        {
            title: 'Boxed Parent Cat',
            dataIndex: 'boxed_parent_category',
        },
        {
            title: 'Boxed Cat',
            dataIndex: 'boxed_category',
        },
        {
            title: 'Boxed Subcat',
            dataIndex: 'boxed_subcategory',
        },
        {
            title: 'Options',
            dataIndex: 'options',
            slots: {
                customRender: 'viewMore',
            }
        }
    ];
    rows = [];
    loadingTable = true;
    pagination = {
        current: 1,
        pageSize: 25,
        total: 1
    };
    showModal = false;
    viewingRow = null;

    showViewModal(record: any) {
        this.viewingRow = record;
        this.showModal = true;
    }

    async uploadCsv() {
        try {
            const fd = new FormData();
            fd.append('file', this.csvFile);

            this.loadingUpload = true;
            const res = await axios.post(`${process.env.VUE_APP_API_URL!}/abg-category-mapping/upload-csv`, fd, {
                headers: {
                    APIToken: `${window.localStorage.getItem(process.env.VUE_APP_ACCESS_TOKEN_IDENTIFIER!)}`
                }
            });
            const data = await res.data;
            this.loadingUpload = false;
            if (data.error) {
                message.error(data.error_message);
            } else {
                message.success('Successfully uploaded CSV File! Please check back shortly.');
            }
        } catch (error) {
            this.loadingUpload = false;
            console.log(error);
            message.error('Sorry, something went wrong. Please try again later.');
        }
    }

    beforeUpload = (file: FileItem) => {
        this.csvFile = file;
        this.csvFileLabel = file.name;
        return false;
    }

    handleTableChange(event: any) {
        const current = event.current;
        this.pagination.current = current;
        this.loadingTable = true;
        this.getStagingTable();
    }

    async deleteRow(row: any) {
        if (confirm('Are you sure you want to delete this record?')) {
            row.deleteLoading = true;
            try {
                const fd = new FormData();
                fd.append('id', row.id);
                const res = await axios.post(`${process.env.VUE_APP_API_URL!}/abg-category-mapping/delete`, fd, {
                    headers: {
                        APIToken: `${window.localStorage.getItem(process.env.VUE_APP_ACCESS_TOKEN_IDENTIFIER!)}`,
                        'Content-Type': 'application/json',
                    }
                });
                const data = res.data;
                row.deleteLoading = false;
                if (data.error) {
                    message.error(data.error_message);
                } else {
                    message.success('Successfully deleted row!');
                    this.getStagingTable();
                }
            } catch (error) {
                row.deleteLoading = false;
                console.log(error);
                message.error('Sorry, something went wrong. Please check your internet connection and try again later.');
            }
        }
    }

    async getStagingTable() {
        try {
            this.loadingTable = true;
            let url = `${process.env.VUE_APP_API_URL!}/abg-category-mapping?page=${this.pagination.current}`;
            const res = await axios.get(url, {
                headers: {
                    APIToken: `${window.localStorage.getItem(process.env.VUE_APP_ACCESS_TOKEN_IDENTIFIER!)}`
                }
            });
            const data = await res.data;
            if (data.error) {
                message.error(data.error_message);
            } else {
                data.abg_category_mapping.data.forEach((row: any) => {
                    row.key = row.id;
                    row.deleteLoading = false;
                });
                const currentPage = this.pagination.current;
                this.pagination = {
                    current: currentPage,
                    pageSize: 25,
                    total: data.abg_category_mapping.count
                };
                this.loadingTable = false;
                this.rows = data.abg_category_mapping.data;
            }
        } catch (error) {
            console.log(error);
            message.error('Sorry, something went wrong. Please try again later.');
        }
    }

    mounted() {
        console.log('[APP] Dashboard Mounted');
        this.getStagingTable();
    }
}
